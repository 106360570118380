:root {
  --yellow: #f2b619;
  --white: #ffffff;
  --primary: #04a5c2;
  --color-lightgrey: #f9f9f9;
  --bg-auth: rgba(255, 255, 255, 0.3);
}

.wd-styl {
  width: 170px;
}

.imgcard-styl {
  width: 150px;
  height: 150px;
}
.imgwidth {
  width: 130px;
}
.text-color {
  color: var(--primary);
}
.header-height {
  height: 600px;
}
.landing-footer {
  height: 500px;
}
.text-commingsoon {
  font-size: 100px;
}

.bg-auth {
  background-color: var(--bg-auth);
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.separator {
  border: none;
  border-top: 1px solid black;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
}
.logo-landing {
  /* width: 70%;
  height: 70%; */
  width: 100%;
  height: 100%;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  width: 60%;
  margin: auto;
}
.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}
.btn:hover {
  background-color: blue;
  color: aliceblue;
}
.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}
.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}
.bnbHomecards-items {
  width: 32.6%;
}
.bnbdetailPage-imgSlideWrapper {
  width: 70%;
}
@media screen and (max-width: 767px) {
  .mtmob {
    margin-top: 100px;
  }
  .text-commingsoon {
    font-size: 50px;
  }
  .header-height {
    height: 550px;
  }
  .bnbHomecards-items {
    width: 48.8%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .mtmob {
    /* margin-top: 100px; */
  }
}

.root .arrowWrapper {
  border: 2px solid rgb(37, 230, 3) !important;
  color: rgb(4, 193, 130) !important;
  background-color: darkorchid !important;
}

/* Bnb NavBar */
.bnbNvar_wrapper_home {
  /* background-color: #007aa2; */
  background-color: transparent;
}
.bnbNvar_wrapper {
  background-color: #FBFFFB;
  /* background-color: transparent; */
}
.bnbNav-items li {
  padding: 0px 10px;
}
.height-line {
  height: 100vh;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .height-line {
    height: 100vh;
    width: 100%;
  }

  .bnbNav-items li {
    padding: 0px 10px;
    font-size: 13px;
  }
  /* .image-slide-wrapper{
  width: 100%;
 }
 .image-slide {
  width: 100%;
 } */
  .bnbdetailPage-imgSlideWrapper {
    margin-top: 16px;
  }
  .bnbHomecards-items {
    width: 100%;
    margin: 2px 0px;
  }
  .detailpage-wrapper {
    display: block;
  }
  .dp-item-wrapper {
    width: 100%;
  }
  .dp-reserveCardWrapper {
    width: 100vw;
  }
  .bnbdetailPage-imgSlideWrapper {
    width: 100%;
  }
  .reserveDetailWrapper {
    display: block;
    width: auto;
  }
  .memberForm-wrapper {
    width: 94%;
  }
}
.reserveDetailWrapper {
  display: block;
  width: auto;
}
.footer-bg-col {
  background-color: #34789e;
}
.bn-text-col {
  color: #b4b6b7;
}

.slick-dots li{
    margin: 0 0 !important;
}
.slick-dots li.slick-active button:before{
  border: yellow !important;
  color: transparent !important;
}