@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: local('Poppins'), url('../fonts/AvenirLTProRoman.otf') format('truetype');
  /* font-weight: bold; */
}

/* @font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: bold;
} */

:root {
  --yellow: #f2b619;
  --white: #ffffff;
  --primary: #04a5c2;
  --color-btn: #04a5c2;
  --color-lightgrey: #f9f9f9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Avenir', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroll bar */
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

::-webkit-scrollbar {
  height: 5px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 5px;
  /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}

/* index styl */

.input-styl {
  @apply w-full px-3 py-3 text-black placeholder-black transition-all duration-150 ease-linear bg-white border border-gray-300 rounded shadow focus:outline-none focus:ring;
}

.btn-styl {
  @apply w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-black transition-all duration-150 ease-linear border-2 rounded shadow border-color-primary bg-color-primary hover:shadow-lg;
}

.btn-styl-blue {
  @apply px-10 py-2 my-3 text-white bg-blue-900 rounded-full;
}

.mobileview {
  @apply hidden lg:hidden md:hidden xs:block sm:block;
}

.desktopview {
  @apply block lg:block md:block sm:hidden xs:hidden;
}

.center-styl {
  @apply items-center content-center justify-center;
}

.landing-text {
  @apply mt-0 mb-4 text-lg font-light leading-relaxed text-gray-600;
}

.card-styl {
  @apply relative flex flex-col w-full min-w-0 p-8 mb-8 break-words bg-white border rounded-lg shadow-lg border-color-grey;
}

input[type='file'] {
  display: none;
}

a {
  cursor: pointer;
}

.input-hidden {
  border: 0;
  outline: 0;
  background-color: 'red';
}

.input-hidden:focus {
  outline: none !important;
}

/* input[type='date']:in-range::-webkit-datetime-edit-year-field,input[type='date']:in-range::-webkit-datetime-edit-month-field,input[type='date']:in-range::-webkit-datetime-edit-day-field,input[type='date']:in-range::-webkit-datetime-edit-text{  color: transparent;} */

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.PhoneInputInput {
  /* stop-color: #04a5c2; */
  height: 40px !important;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

.textCommonPrp {
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
}

.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-next-button {
  background-color: transparent !important;
  color: #AFB5C1 !important;
  border: none !important;
}

.rotat {
  transform: rotate(90deg);
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotatediv {
  animation: rotateAnimation 5s linear infinite;
  /* Adjust duration and timing function as needed */
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(300deg);
  }
}

.rotatedivtwo {
  animation: rotateAnimation 5s linear infinite;
  /* Adjust duration and timing function as needed */
}

@media (max-width: 768px) {
  .custom {
    font-size: 50px;
  }
}

.propertyType_responsiveness {
  padding-top: 30vh !important;
  padding-left: 10px;
  margin-top: 10vh !important;
  height: 87vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.spaceType_responsiveness {
  padding-top: 20vh !important;
  padding-bottom: 20vh !important;
  padding-left: 20px;
  margin-top: 10vh !important;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.addAmeneties_responsiveness {
  padding-top: 22vh !important;
  padding-left: 20px;
  margin-top: 10vh !important;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.addImages_responsiveness {
  padding-top: 20vh !important;
  padding-bottom: 10vh !important;
  padding-left: 20px;
  margin-top: 13vh !important;
  height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.preview_responsiveness {
  padding-top: 50vh !important;
  padding-left: 20px;
  margin-top: 10vh !important;
  height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* mobile */
@media (max-width: 600px) {
  .propertyType_responsiveness {
    padding-top: 150vh !important;
    padding-left: 10px;
  }

  .spaceType_responsiveness {
    height: 250vh;

  }

  .addAmeneties_responsiveness {
    height: 310vh;

  }

  .addImages_responsiveness {
    height: 150vh;
    overflow-y: auto;

  }

  .preview_responsiveness {
    height: 160vh;
    overflow-y: auto;

  }

}

/* tablet */
@media (min-width: 601px) and (max-width: 1023px) {
  .propertyType_responsiveness {
    padding-top: 80vh !important;
    padding-left: 10px;
  }

  .spaceType_responsiveness {
    height: 130vh;
    padding-bottom: 20vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .addAmeneties_responsiveness {
    height: 170vh;
  }

  .addImages_responsiveness {
    height: 130vh;
    overflow-y: auto;
  }

  .preview_responsiveness {
    height: 130vh;
    overflow-y: auto;
  }
}

/* lg screen */
@media (min-width: 1024px) and (max-width: 1280px) {
  .propertyType_responsiveness {
    padding-top: 60vh !important;
    padding-left: 10px;
    margin-top: 10vh !important;
    height: 87vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .spaceType_responsiveness {
    padding-top: 50vh !important;
    padding-bottom: 20vh !important;
    padding-left: 20px;
    margin-top: 10vh !important;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .addAmeneties_responsiveness {
    padding-top: 50vh !important;
    padding-left: 20px;
    margin-top: 10vh !important;
    height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .addImages_responsiveness {
    padding-top: 50vh !important;
    padding-bottom: 10vh !important;
    padding-left: 20px;
    margin-top: 17vh !important;
    height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .preview_responsiveness {
    padding-top: 65vh !important;
    padding-left: 20px;
    margin-top: 10vh !important;
    height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}